<template>
  <BaseSvg
    v-bind="$props"
    viewBox="0 0 40 33"
    path="M37.500,19.000 L2.500,19.000 C1.119,19.000 -0.000,17.881 -0.000,16.500 C-0.000,15.119 1.119,14.000 2.500,14.000 L37.500,14.000 C38.881,14.000 40.000,15.119 40.000,16.500 C40.000,17.881 38.881,19.000 37.500,19.000 ZM37.500,5.000 L2.500,5.000 C1.119,5.000 -0.000,3.881 -0.000,2.500 C-0.000,1.119 1.119,0.000 2.500,0.000 L37.500,0.000 C38.881,0.000 40.000,1.119 40.000,2.500 C40.000,3.881 38.881,5.000 37.500,5.000 ZM2.500,28.000 L37.500,28.000 C38.881,28.000 40.000,29.119 40.000,30.500 C40.000,31.881 38.881,33.000 37.500,33.000 L2.500,33.000 C1.119,33.000 -0.000,31.881 -0.000,30.500 C-0.000,29.119 1.119,28.000 2.500,28.000 Z"
  />
</template>

<script>
  import BaseSvg from '../BaseSvg';
  import SvgIcons from '../../../mixins/SvgIcons';

  export default {
    name: 'Hamburger',
    mixins: [SvgIcons],
    components: {
      BaseSvg
    }
  };
</script>
