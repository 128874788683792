var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:_vm.navClass ? _vm.navClass : 'primary-nav'},[(_vm.showVarianceReportNav)?_c('ul',{staticClass:"main-menu navigation",class:_vm.listClass},_vm._l((_vm.varianceReportsNavigation),function(route,i){return _c('li',{key:i,class:[
        _vm.$route.meta.navSection === route.meta.navSection
          ? 'section-active'
          : ''
      ]},[_c('router-link',{attrs:{"to":route.path}},[_vm._v(_vm._s(route.meta.title))])],1)}),0):_vm._e(),(_vm.showPopBuilderRxTmNav)?_c('ul',{staticClass:"main-menu navigation",class:_vm.listClass},_vm._l((_vm.popBuilderRxTmNavigation),function(route,i){return _c('li',{key:i,class:[
        _vm.$route.meta.navSection === route.meta.navSection
          ? 'section-active'
          : ''
      ]},[_c('router-link',{attrs:{"to":route.meta.navPath ? route.meta.navPath : route.path}},[_vm._v(_vm._s(route.meta.title))])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }