<template>
  <div>
    <AppHeader :productTitle="productTitle" v-if="isLoggedIn" />
    <portal-target name="before-content"></portal-target>
    <div class="flex">
      <div class="rfs-m-8 flex-1">
        <div id="appContent">
          <h2
            v-if="!$route.meta.hideTitleHeader"
            class="content-title rfs-m-8-b"
          >
            {{ $route.meta.title }}
          </h2>
          <router-view />

          <!-- pages with iframes have to load outside of normal routing so that the dom is not destroyed when switching tabs
            (otherwise iframe requests new each time you switch back) -->
          <template v-if="$route.meta.alternateContentLayout"></template>
        </div>
      </div>
      <portal-target name="after-content">
        <!--
      This component can be located anwhere in your App.
      The slot content of the above portal component will be rendered here.
      -->
      </portal-target>
    </div>
    <portal-target name="modal" multiple></portal-target>
  </div>
</template>

<script>
  import AppHeader from '@/components/base/AppHeader';

  export default {
    name: 'AppLayout',
    components: {
      AppHeader
    },
    data() {
      return {
        showHeader: true
      };
    },
    computed: {
      productTitle() {
        return this.$route.meta.productTitle;
      }
    },
    methods: {
      breakpointUpdateLocal(e) {
        this.showHeader = this.$breakpoints.lessThan('lg');
      }
    },
    created() {
      this.showHeader = this.$breakpoints.lessThan('lg');
      window.addEventListener('resize', this.breakpointUpdateLocal);
    },
    destroyed() {
      window.removeEventListener('resize', this.breakpointUpdateLocal);
    }
  };
</script>

<style lang="scss">
  @use '@/assets/scss/responsive';
  @use './node_modules/include-media/dist/include-media.scss' as im with
    ($breakpoints: responsive.$breakpoints);

  // base styles for all viewport sizes - mobile first

  // responsive styles

  @include im.media('<=lg') {
    .sliderslider {
      z-index: 1000;
    }
  }
</style>
