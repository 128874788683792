<template>
  <span class="crca-icon">
    <slot name="beforeIcon"></slot>
    <component :is="icon" :colorClass="colorClass" :size="size"></component>
    <slot name="afterIcon"></slot>
  </span>
</template>

<script>
  import SvgIcons from '../../mixins/SvgIcons';
  import Vue from 'vue';
  const req = require.context('./svg/', true, /\.(js|vue)$/i);
  req.keys().map((key) => {
    const name = key.match(/\w+/)[0];
    return Vue.component(name, req(key).default);
  });

  export default {
    name: 'CrcaIcon',
    mixins: [SvgIcons],
    props: {
      icon: String
    }
  };
</script>
