<template>
  <BaseSvg
    :colorClass="colorClass"
    :size="size"
    viewBox="0 0 76 69"
    path="M72.4 46.8L53.3 65.9C49.8 69.4 44.1 69.4 40.6 65.9 37.1 62.4 37.1 56.7 40.6 53.2L59.7 34.1C63.2 30.6 68.9 30.6 72.4 34.1 75.9 37.6 75.9 43.3 72.4 46.8ZM52.6 45.4L43.1 54.9C40.7 57.3 40.7 61.1 43.1 63.4 45.4 65.8 49.2 65.8 51.6 63.4L61.1 53.9 52.6 45.4ZM15.5 41C14.7 41 14 41.7 14 42.5 14 43.3 14.7 44 15.5 44L44.4 44 37.6 50.8C37.6 50.8 37.5 50.9 37.4 51L15.5 51C14.7 51 14 51.7 14 52.5 14 53.3 14.7 54 15.5 54L35.2 54C33.1 58.1 33.2 63 35.4 67L10 67C7.8 67 6 65.2 6 63L6 4C6 1.8 7.8 0 10 0L53 0C55.2 0 57 1.8 57 4L57 31.4 47.4 41 15.5 41ZM25 10L18 10 18 29 25 29 25 10ZM35 15L28 15 28 29 35 29 35 15ZM45 7L38 7 38 29 45 29 45 7ZM49 32.5C49 31.7 48.3 31 47.5 31L15.5 31C14.7 31 14 31.7 14 32.5 14 33.3 14.7 34 15.5 34L47.5 34C48.3 34 49 33.3 49 32.5ZM0 61L0 8C0 5.8 1.8 4 4 4L4 65C1.8 65 0 63.2 0 61Z"
  />
</template>

<script>
  import BaseSvg from '../BaseSvg';
  import SvgIcons from '../../../mixins/SvgIcons';

  export default {
    name: 'VarianceReport',
    mixins: [SvgIcons],
    components: {
      BaseSvg
    }
  };
</script>
