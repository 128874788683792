<template>
  <nav :class="navClass ? navClass : 'primary-nav'">
    <ul
      class="main-menu navigation"
      :class="listClass"
      v-if="showVarianceReportNav"
    >
      <li
        v-for="(route, i) in varianceReportsNavigation"
        :key="i"
        :class="[
          $route.meta.navSection === route.meta.navSection
            ? 'section-active'
            : ''
        ]"
      >
        <router-link :to="route.path">{{ route.meta.title }}</router-link>
      </li>
    </ul>
    <ul
      class="main-menu navigation"
      :class="listClass"
      v-if="showPopBuilderRxTmNav"
    >
      <li
        v-for="(route, i) in popBuilderRxTmNavigation"
        :key="i"
        :class="[
          $route.meta.navSection === route.meta.navSection
            ? 'section-active'
            : ''
        ]"
      >
        <router-link :to="route.meta.navPath ? route.meta.navPath : route.path">{{ route.meta.title }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  import varianceRoutes from '../../router/variance_reports';
  import popBuilderRxTmRoutes from '../../router/pop_builder_rxtm';
  import { checkPagePermission } from '../../js/utilities/permissions';

  export default {
    name: 'Navigation',
    props: {
      product: String,
      listClass: String,
      showModules: Array,
      navClass: String
    },
    data() {
      return {};
    },
    computed: {
      varianceReportsNavigation() {
        return varianceRoutes.filter(function (r) {
          return (
            r.name === r.meta.navSection &&
            (!r.meta.permission || checkPagePermission(r.meta.permission))
          );
        });
      },
      popBuilderRxTmNavigation() {
        return popBuilderRxTmRoutes.filter(function (r) {
          return (
            r.name === r.meta.navSection &&
            (!r.meta.permission || checkPagePermission(r.meta.permission))
          );
        });
      },
      showVarianceReportNav() {
        if (this.showModules) {
          if (this.showModules.includes('variancereports')) {
            return true;
          }
          return false;
        }
        if (this.$route.meta.module === 'variancereports') {
          return true;
        }
        return false;
      },
      showPopBuilderRxTmNav() {
        if (this.showModules) {
          if (this.showModules.includes('popBuilderRxTm')) {
            return true;
          }
          return false;
        }
        if (this.$route.meta.module === 'popBuilderRxTm') {
          return true;
        }
        return false;
      }
    }
  };
</script>
