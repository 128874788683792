<template>
  <header id="appHeader" class="flex items-center rfs-p-2 rfs-p-8-l">
    <slider
      :width="screenWidth"
      :opacity="0.15"
      class="flex-none"
      iconColor="color-grey-300"
    >
      <div style="padding: 0px 55px 0px 30px;">
        <multi-select-checkbox-dropdown
          v-model="hospitalSegment"
          :options="hospitalOptions"
          optionLabel="text"
          fieldLabel="Hospital"
          :clearable="false"
          class="form-group form-row"
          :searchOption="searchedOptions"
          @searchedOptions="updateSearchOptions"
        />
      </div>
      <div class="slider-main">
        <div class="rfs-m-8-b" v-if="!breakpoints.greaterThanMd">
          <h1 class="rfs-m-8-b flex-auto" v-html="productTitle"></h1>
          <navigation />
        </div>
        <div class="auto-height" v-if="hasOtherProductAccess">
          <span class="fw-sb rfs-22">Other Compare Modules</span>
          <ul class="fw-m rfs-26">
            <li v-if="productModule == 'variancereports'">
              <router-link to="/pop-builder-rx" class="other-product-title">
                <pop-builder-formatted tm />
              </router-link>
              <navigation
                :showModules="['popBuilderRxTm']"
                navClass="secondary-nav"
              />
            </li>
            <li v-else-if="productModule == 'popBuilderRxTm'">
              <router-link to="/variance-reports" class="other-product-title">
                <span>Variance Reports</span>
              </router-link>
              <navigation
                :showModules="['variancereports']"
                navClass="secondary-nav"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="user-controls">
        <button
          class="btn btn-primary logout-button order-2"
          data-logout-btn
          @click="logout"
        >
          Return to Trisus Home
        </button>
        <loader id="logoutLoader" class="order-3" v-if="logoutLoader" />
      </div>
    </slider>
     <div id="headerlogo" class="cranewarelogo">
        <img src="../../assets/img/tcg-logo_white.svg" class="logoimage"/>
     </div>
     <span  class="spacespan"></span>
     <div class="flex items-start">
      <div id="appControls" class="flex-1 flex items-center">
        <div class="flex-none">
          <h1 class="rfs-m-4-r" v-html="productTitle"></h1>
        </div>
        <navigation
          id="topNav"
          class="flex-1 top-nav"
          :listClass="`flex items-start gap-8 items-center`"
          v-if="breakpoints.greaterThanMd"
        />
      </div>
      <portal-target name="after-page-title" />
      <div
        id="rightControls"
        :class="!breakpoints.greaterThanMd ? 'flex-auto' : ''"
      >
        <slot name="rightControls" />
      </div>
    </div>
    <portal-target class="flex-last-to-right" name="header-end" />
  </header>
</template>

<script>
  import PopBuilderFormatted from '@/components/branding/PopBuilderFormatted.vue';
  import Slider from './Slider';
  import Navigation from './Navigation';

  import ability from '../../js/utilities/ability';
  import selectStore from '../../store/modules/global/filters/select';
  import multiSelectCheckboxDropdown from '@/components/base/forms/multi_select_checkbox_dropdown';

  import useLogout from '@/composables/useLogout.js';
  import { mapState } from 'vuex';

  export default {
    name: 'AppHeader',
    setup(_, context) {
      const logout = () => useLogout(context);
      return {
        logout
      };
    },
    components: {
      Slider,
      Navigation,
      PopBuilderFormatted,
      multiSelectCheckboxDropdown
    },
    props: {
      productTitle: String,
      sectionId: String
    },
    computed: {
      ...mapState('auth', {
        selectedHospitalList: (state) => state.selectedHospitalList,
        hospitalSearchOption: (state) => state.hospitalSearchOption,
      }),
      productModule() {
        return this.$route.meta.module;
      },
      eulaAccepted() {
        return ability.can('pass', 'eula');
      },
      hasOtherProductAccess() {
        if (this.productModule == 'variancereports') {
          return ability.can('access', 'population-builder-rx');
        }

        if (this.productModule == 'popBuilderRxTm') {
          return ability.can('access', 'variance-reports');
        }

        return false;
      },
      hasVarianceReportAccess() {
        return this.$storage.get('hasVarianceReportAccess');
      },
      hasPopBuilderAccess() {
        return this.$storage.get('hasPopBuilderAccess');
      },
      app() {
        return document.getElementById('app');
      },
      logoutLoader() {
        return this.$store.getters['logOutLoaderStore' + '/getValue'];
      },
      hospitalSegment: {
        get() {
          return this.selectedOptions;
        },
        set(value) {
          this.$store.commit('auth/setSelectedHospitalList', value);
          this.selectedOptions = value;
        }
      }
    },
    data() {
      return {
        hospitalOptions: [],
        selectedOptions: [],
        searchedOptions: undefined,
        screenWidth: 40
      };
    },
    methods: {
      updateSearchOptions(so, sv) {
        this.$store.commit('auth/setHospitalSearchOption', so);
        this.$store.commit('auth/setSelectedHospitalList', sv);
      }
    },
    watch: {
      selectedHospitalList(v) {
        if (v !== undefined && v !== null && v.length > 0) {
          this.hospitalSegment = v;
        }
      },
      hospitalSearchOption(v) {
        if (v !== undefined && v !== null) {
          this.searchedOptions = v;
        }
      }
    },
    beforeMount(){
      this.screenWidth = (screen.width <= 768 )? 100 : 40 ;
    },
    created() {
      this.smallScreens = this.$breakpoints.lessThan('md');
      window.addEventListener('resize', this.breakpointUpdate);

      /* searchOptions */
      this.searchedOptions = this.$store.getters['auth/getHospitalSearchOption'];
      /*** Multi-select hospital  */
      let hospitals = this.$store.getters['auth/userHospitalList'];
      let options = [];
      for (let i in hospitals) {
        options.push({ text: hospitals[i].name, value: hospitals[i].id });
      }
      // 1st option as selected
      let selectedHospitalList = this.$store.getters['auth/getSelectedHospitalList'];
      if (selectedHospitalList !== undefined && selectedHospitalList !== null && selectedHospitalList.length > 0) {
        this.selectedOptions = selectedHospitalList;
      } else {
        this.selectedOptions.push(options[0]);
      }

      this.hospitalOptions = options;
    },
    destroyed() {
      window.removeEventListener('resize', this.breakpointUpdate);
      this.app.style.marginLeft = 0;
    },
    mounted() {
      this.magicModule('logOutLoaderStore', selectStore);
    }
  };
</script>

<style lang="scss">
  @use '@/assets/scss/vars.scss' as v;
  #appHeader {
    .navIcon {
      padding-right: 16px;
    }
  }

  #logoLink {
    display: block;
    text-align: left;
    margin: 0 auto;
  }

  #logo {
    height: 43px;
  }

  #rightControls {
    text-align: right;
  }

  .slider-main {
    overflow-x: hidden;
    height: 90%;
    margin-top: 32px;
    padding: 0 32px 32px 32px;
  }

  .auto-height {
    height: calc(100vh - 420px);
    display: inline-table;
    width: 100%;
  }

  .other-product {
    height: 136px;
    max-height: 136px;
    overflow: hidden;
  }

  .user-controls {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    border-top: 1px solid;
    left: 0;
    width: 100%;
    padding: 8px 32px;
    background: v.$surfaceBackground;

    ::before {
      position: absolute;
    }
  }

  #logoLinkSlider {
    margin: 1rem 0 0;
  }

  #logoutLoader {
    vertical-align: middle;
    margin-left: 10px;
  }

  .cranewarelogo{
    border-right: medium solid white;
    height: 40px;
    position: relative;
  }

  .logoimage{
    height: 50px;
    position: relative;
    top: -5px;
  }

  .spacespan{
    padding-right:10px;
  }

</style>
