<template>
  <div v-clickoutside="clickoutside">
    <!-- <div v-if="open" class="overlay"></div> -->
    <div>
      <!-- Nav Links -->
      <div class="slider" :style="[menuDirection, menuWidth, position]">
        <button
          type="button"
          ref="closeMenu"
          class="close-btn btn btn-text"
          v-if="closeButton"
          @click.prevent="closeMenu"
        >
          Close Menu
        </button>
        <slot />
      </div>
      <!-- Hamburger Menu -->
      <nav class="navIcon crca-icon" :style="iconDirection">
        <a
          href="javscript:void(0)"
          @click.prevent="toggleMenu"
          data-test-ref="sideBarLink"
          v-tooltip="'Compare Menu'"
        >
          <crca-icon
            icon="hamburger"
            :colorClass="iconColor"
            :size="iconSize"
          />
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
  import styles from '../../assets/scss/slider_vars.scss';
  import CrcaIcon from '@/components/icons/CrcaIcon.vue';

  export default {
    name: 'slider',
    components: {
      CrcaIcon
    },
    props: {
      width: {
        type: Number,
        required: false,
        default: 250
      },
      startOpen: {
        type: Boolean,
        required: false,
        default: false
      },
      permanent: {
        type: String,
        required: false,
        validator: (value) =>
          ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].indexOf(value) > -1
      },
      direction: {
        type: String,
        required: false,
        default: 'left',
        validator: (value) => ['left', 'right'].indexOf(value) > -1
      },
      opacity: {
        type: Number,
        required: false,
        default: 0
      },
      closeBtn: {
        type: Boolean,
        required: false,
        default: true
      },
      iconSize: {
        type: String,
        required: false,
        default: 'small'
      },
      iconColor: {
        type: String,
        required: false,
        default: 'color-grey'
      }
    },
    data() {
      return {
        styles: styles,
        menuWidth: {
          width: `${this.width}%`
        },
        position: {
          left: `-${this.width}%`
        },
        open: false,
        closeButton: this.closeBtn,
        permanentOpen: false
      };
    },
    methods: {
      breakpointUpdateLocal(e) {
        this.permanentOpen = !this.$breakpoints.lessThan(this.permanent);
      },
      clickoutside() {
        if (this.open && !this.permanentOpen) {
          this.closeMenu();
        }
      },
      openMenu() {
        if (this.format === 'full') {
          this.menuWidth = {
            width: '100%'
          };
        }
        if (this.permanentOpen) {
          if (this.direction === 'left') {
            this.app.style.marginLeft = `${this.width}px`;
            this.app.style.transition = 'margin-left 500ms';
          }
        }
        this.position = {
          left: '0px'
        };
        this.open = true;
        this.$refs.closeMenu.focus();
      },
      closeMenu() {
        this.position = {
          left: `-${this.width}%`
        };
        if (this.direction === 'left') {
          this.app.style.marginLeft = 0;
        }
        this.open = false;
      },
      toggleMenu() {
        if (!this.open) {
          this.openMenu();
        } else {
          this.closeMenu();
        }
      }
    },
    computed: {
      menuDirection() {
        return this.direction === 'right' ? { right: 0 } : { left: 0 };
      },
      iconDirection() {
        return { float: this.direction };
      },
      app() {
        return document.getElementById('app');
      }
    },
    watch: {
      permanentOpen(val) {
        if (val) {
          if (this.direction === 'left') {
            this.app.style.marginLeft = `${this.width}px`;
          }
          this.open = true;
          this.closeButton = false;
        } else {
          this.app.style.marginLeft = 0;
          this.closeButton = this.closeBtn;
          this.open = this.startOpen;
        }
      },
      open(val) {
        if (val) {
          this.openMenu();
        } else {
          this.closeMenu();
        }
      },
      $route(to, from) {
        if (!this.permanentOpen) {
          this.closeMenu();
        }
      }
    },
    mounted() {
      if (!this.app) {
        console.warn(
          `[Slider] The format was set to '${this.format}', but there is no element with an id 'app'. Add id='app' to the element to move.`
        );
      }
      if (this.startOpen) {
        this.open = true;
      }
    },
    created() {
      this.permanentOpen = !this.$breakpoints.lessThan(this.permanent);
      window.addEventListener('resize', this.breakpointUpdateLocal);
    },
    destroyed() {
      window.removeEventListener('resize', this.breakpointUpdateLocal);
    }
  };
</script>
