<template>
  <BaseSvg
    v-bind="$props"
    viewBox="0 0 43 32"
    path="M39.984,18.937 C38.353,18.937 37.031,17.615 37.031,15.984 C37.031,14.353 38.353,13.031 39.984,13.031 C41.615,13.031 42.938,14.353 42.938,15.984 C42.938,17.615 41.615,18.937 39.984,18.937 ZM31.016,19.000 L18.996,19.000 L18.996,30.622 C18.299,31.248 17.168,31.248 16.472,30.622 L1.322,17.031 C0.625,16.406 0.625,15.392 1.322,14.766 L16.472,1.176 C17.168,0.550 18.299,0.550 18.996,1.175 L18.996,13.031 L31.016,13.031 C32.664,13.031 34.000,14.367 34.000,16.015 L34.000,16.016 C34.000,17.664 32.664,19.000 31.016,19.000 Z"
  />
</template>

<script>
  import BaseSvg from '../BaseSvg';
  import SvgIcons from '../../../mixins/SvgIcons';

  export default {
    name: 'Arrow',
    mixins: [SvgIcons],
    components: {
      BaseSvg
    }
  };
</script>
