<template>
  <BaseSvg
    v-bind="$props"
    viewBox="0 0 95 67"
    path="M68.9 46.9C68.3 46.2 64.4 41.8 63 37 62.5 35.3 62.3 33.6 62.3 32 64.8 31.3 65.7 31 65.7 27.9 65.7 25.3 64 26.2 63.3 21.3 63 19.3 61.4 21.3 61.1 16.7 61.1 14.9 62 14.5 62 14.5 62 14.5 61.6 11.8 61.4 9.7 61.3 8 62 4.8 64.7 2.8 74.4 0.5 73.6 1.1 73.9 1.4 79 2.9 80.2 7.5 80 9.7 79.8 11.8 79.4 14.5 79.4 14.5 79.4 14.5 80.3 14.9 80.3 16.7 80 21.3 78.4 19.3 78.1 21.3 77.4 26.2 75.7 25.3 75.7 28 75.7 32.3 77.5 30.9 83.1 33.4 88.7 35.8 94.6 38.9 94.6 44.2L94.6 46.9 68.9 46.9ZM59.7 38.2C60.3 39.9 62.7 41.9 62.7 41.9 62.7 41.9 60.8 43.4 58.5 44.1 56.9 44.5 55 44.3 54.4 44.3 53.8 45 53.4 45.5 53.4 46.8 53.4 51.5 55.3 50 61.3 52.6 67.3 55.1 73.5 58.4 73.5 64.1L73.5 66.9 22.9 66.9 22.9 64.1C22.9 58.4 29 55.1 35 52.6 41 50 42.9 51.4 42.9 46.8 42.9 46.5 42.9 46.2 42.8 45.9L42.8 45.9C42.8 45.9 42.8 45.9 42.8 45.8 42.7 45.4 42.5 45 42.3 44.7 42.1 44.6 42 44.4 41.9 44.3 41.1 44.4 39.3 44.5 37.8 44.1 35.5 43.4 33.6 41.9 33.6 41.9 33.6 41.9 36 39.9 36.6 38.2 37 37.2 35.7 36.3 35.7 36.3 35.7 36.3 37 34.4 37.4 31.7 37.7 29.5 38 26.1 38 26.1 38 26.1 38.6 23.3 40.2 21.4 41.9 19.5 44.9 18.4 44.9 18.4L46.1 18.3C46.5 18.2 46.8 18.2 47.1 18.2 48.1 18.1 48.5 18 49.1 18.1 49.2 18.2 49.3 18.2 49.5 18.3L51.4 18.4C51.4 18.4 54.4 19.5 56.1 21.4 57.7 23.3 58.3 26.1 58.3 26.1 58.3 26.1 58.6 29.5 58.9 31.7 59.3 34.4 60.6 36.3 60.6 36.3 60.6 36.3 59.4 37.2 59.7 38.2ZM34.1 16.7C33.8 21.2 32.3 19.3 32 21.3 31.2 26.1 29.5 25.3 29.5 27.9 29.5 31.3 30.6 31.2 33.7 32.2 33.6 33.7 33.5 35.4 33 37 31.6 41.8 27.7 46.2 27.1 46.9L0.6 46.9 0.6 44.2C0.6 38.9 6.5 35.8 12.1 33.3 17.8 30.9 19.6 32.3 19.6 27.9 19.6 25.3 17.9 26.1 17.1 21.3 16.8 19.3 15.3 21.2 15 16.7 15 14.8 15.8 14.4 15.8 14.4 15.8 14.4 15.4 11.7 15.2 9.6 15.1 7.9 15.8 4.7 18.5 2.7 28.2 0.3 27.5 1 27.8 1.3 32.8 2.7 34.1 7.4 33.9 9.6 33.7 11.7 33.3 14.4 33.3 14.4 33.3 14.4 34.1 14.8 34.1 16.7Z"
  />
</template>

<script>
  import BaseSvg from '../BaseSvg';
  import SvgIcons from '../../../mixins/SvgIcons';

  export default {
    name: 'PopBuilder',
    mixins: [SvgIcons],
    components: {
      BaseSvg
    }
  };
</script>
