<template>
  <svg
    v-bind:class="[colorClass, size]"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
  >
    <path :d="path" />
  </svg>
</template>

<script>
  import SvgIcons from '../../mixins/SvgIcons';

  export default {
    name: 'BaseSvg',
    mixins: [SvgIcons],
    props: {
      viewBox: String,
      path: String
    }
  };
</script>
